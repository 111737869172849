<template>
  <ValidationObserver ref="form5">
    <form>
      <v-row wrap>
        <v-col cols="12">
          <h3 class="headline">
            ORDENADORES / REDES SOCIALES / SERVICIOS EN LA NUBE / EMAIL
            MARKETING
          </h3>
        </v-col>
        <v-col cols="12">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Responde</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Dispone de ordenadores fijos?</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.pcFijos.activo = 1">
            <v-icon
              large
              :color="data.pcFijos.activo == 1 ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.pcFijos.activo = 0">
            <v-icon large :color="data.pcFijos.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="data.pcFijos.activo">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-text-field
              :error-messages="errors[0]"
              name="nombreempresavigilancia"
              v-model="data.pcFijos.cantidad"
              label="Cuantos?"
              hint="Escribe la cantidad de ordenadores fijos"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Dispone de ordenadores portátiles?</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.pcPortatiles.activo = 1">
            <v-icon
              large
              :color="data.pcPortatiles.activo == 1 ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.pcPortatiles.activo = 0">
            <v-icon
              large
              :color="data.pcPortatiles.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="data.pcPortatiles.activo">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-text-field
              :error-messages="errors[0]"
              name="ordenadoresportatiles"
              v-model="data.pcPortatiles.cantidad"
              label="Cuantos?"
              hint="Escribe la cantidad de ordenadores portatiles"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Dispone de un servidor local?</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.servLocales.activo = 1">
            <v-icon
              large
              :color="data.servLocales.activo == 1 ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.servLocales.activo = 0">
            <v-icon
              large
              :color="data.servLocales.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="data.servLocales.activo">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-text-field
              :error-messages="errors[0]"
              name="servidorlocal"
              v-model="data.servLocales.donde"
              label="Donde?"
              hint="Donde se encuentran?"
            ></v-text-field>
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Qué servicios en la nube utiliza?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn
            fab
            @click="data.serviciosNube.DropBox = !data.serviciosNube.DropBox"
          >
            <v-icon
              large
              :color="
                data.serviciosNube.DropBox == false ? 'grey' : 'green darken-3'
              "
              >check_circle</v-icon
            >
          </v-btn>
          <span class="font-weight-black title"> DropBox</span>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn
            fab
            @click="data.serviciosNube.Drive = !data.serviciosNube.Drive"
          >
            <v-icon
              large
              :color="
                data.serviciosNube.Drive == false ? 'grey' : 'green darken-3'
              "
              >check_circle</v-icon
            >
          </v-btn>
          <span class="font-weight-black title"> Google Drive</span>
        </v-col>
        <v-col cols="12">
          <v-btn
            fab
            @click="data.serviciosNube.OneDrive = !data.serviciosNube.OneDrive"
          >
            <v-icon
              large
              :color="
                data.serviciosNube.OneDrive == false ? 'grey' : 'green darken-3'
              "
              >check_circle</v-icon
            >
          </v-btn>
          <span class="font-weight-black title"> Microsoft OneDrive</span>
        </v-col>

        <v-col cols="12">
          <v-text-field
            name="serviciosnube"
            v-model="data.serviciosNube.Otros"
            label="¿Utiliza otros?"
            hint="Escribe aquí los otros servicios en nube que utilices si es que lo haces."
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Hace copias de seguridad?</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.copiasSeguridad.activo = 1">
            <v-icon
              large
              :color="
                data.copiasSeguridad.activo == 1 ? 'green darken-3' : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.copiasSeguridad.activo = 0">
            <v-icon
              large
              :color="data.copiasSeguridad.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row wrap v-if="data.copiasSeguridad.activo">
            <v-col cols="12" sm="6">
              <v-card :color="colores.secundario" class="justify-center">
                <v-card-text
                  class="d-flex  justify-center align-center px-2 font-weight-bold"
                  >¿Con qué frecuencia hace las copias?</v-card-text
                >
              </v-card>
            </v-col>
            <v-col cols="12" sm>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="tiemposeguridad"
                  v-model="data.copiasSeguridad.tiempo"
                  label="¿Diario? ¿semanal? ¿Otra frecuencia?"
                  hint="Escribe cada cuánto tiempo se efectuan dichas copias"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card :color="colores.secundario" class="justify-center">
                <v-card-text
                  class="d-flex  justify-center align-center px-2 font-weight-bold"
                  >¿Dónde realiza las copias de seguridad?</v-card-text
                >
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="cuales"
                  v-model="data.copiasSeguridad.seRealizan"
                  label="¿A cuales?"
                  hint="Escribe los equipos a los cuales se les efectua la copia"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card :color="colores.secundario" class="justify-center">
                <v-card-text
                  class="d-flex  justify-center align-center px-2 font-weight-bold"
                  >¿Dónde guarda las copias de seguridad?</v-card-text
                >
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="copiasalmacen"
                  v-model="data.copiasSeguridad.seAlmacenan"
                  label="¿Donde se guardan?"
                  hint="Escribe donde se almacenan dichas copias"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="d-flex d-sm-none">
          <v-card
            :color="colores.primario"
            width="100%"
            class="d-flex justify-center "
          >
            <v-card-text class="white--text  px-2 font-weight-bold"
              >¿Utiliza Redes Sociales para uso profesional?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="6" class="d-none d-sm-flex justify-center">
          <v-card
            :color="colores.secundario"
            width="100%"
            class=" justify-center "
          >
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Utiliza Redes Sociales para uso profesional?</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.usoRedes.activo = 1">
            <v-icon
              large
              :color="data.usoRedes.activo == 1 ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.usoRedes.activo = 0">
            <v-icon large :color="data.usoRedes.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >En caso de ser así, seleciona las utilizadas.</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-row wrap class="d-flex justify-space-between">
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    fab
                    @click="data.usoRedes.facebook = !data.usoRedes.facebook"
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.facebook == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >

                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Facebook</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    fab
                    @click="data.usoRedes.twitter = !data.usoRedes.twitter"
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.twitter == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Twitter</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12"
                  ><v-btn
                    fab
                    @click="data.usoRedes.linkedin = !data.usoRedes.linkedin"
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.linkedin == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Linkedin</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12"
                  ><v-btn
                    fab
                    @click="data.usoRedes.whatsapp = !data.usoRedes.whatsapp"
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.whatsapp == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Whatsapp</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12"
                  ><v-btn
                    fab
                    @click="data.usoRedes.telegram = !data.usoRedes.telegram"
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.telegram == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Telegram</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12"
                  ><v-btn
                    fab
                    @click="data.usoRedes.instagram = !data.usoRedes.instagram"
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.instagram == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Instagram</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    fab
                    @click="
                      data.usoRedes.pagWeb.activo = !data.usoRedes.pagWeb.activo
                    "
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.pagWeb.activo == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0">
                  <span class="font-weight-black title">Página Web</span>
                </v-col>
              </v-row>
            </v-col>

         
        
            <v-col md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    fab
                    @click="
                      data.usoRedes.otros.activo = !data.usoRedes.otros.activo
                    "
                  >
                    <v-icon
                      large
                      :color="
                        data.usoRedes.otros.activo == false
                          ? 'grey'
                          : 'green darken-3'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn></v-col
                >
                <v-col cols="12" class="pt-0 d-flex justify-center">
                  <span class="font-weight-black title">Otros</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"
              v-if="data.usoRedes.pagWeb.activo || data.usoRedes.otros.activo"
              ><v-divider></v-divider
            ></v-col>
            <v-col cols="12" v-if="data.usoRedes.pagWeb.activo">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="urlweb"
                  data-vv-as="Dirección de la web."
                  v-model="data.usoRedes.pagWeb.campo"
                  label="Dirección de la página"
                  hint="Escribe la dirección de la página web de la empresa"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" v-if="data.usoRedes.otros.activo">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  name="otrosredes"
                  v-model="data.usoRedes.otros.campo"
                  label="Indica que otras redes usas."
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Publica imágenes de personas en web o redes
              sociales?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.imagenesRedes.activo = true">
            <v-icon
              large
              :color="
                data.imagenesRedes.activo == 1 ? 'green darken-3' : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.imagenesRedes.activo = false">
            <v-icon
              large
              :color="data.imagenesRedes.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col md="4" sm="6" v-if="data.imagenesRedes.activo">
          <v-row>
            <v-col cols="12">
              <v-btn
                fab
                @click="data.imagenesRedes.socios = !data.imagenesRedes.socios"
              >
                <v-icon
                  large
                  :color="
                    data.imagenesRedes.socios == false
                      ? 'grey'
                      : 'green darken-3'
                  "
                  >check_circle</v-icon
                >
              </v-btn></v-col
            >
            <v-col cols="12" class="pt-0">
              <span class="font-weight-black title">De socios</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="4" sm="6" v-if="data.imagenesRedes.activo">
          <v-row>
            <v-col cols="12"
              ><v-btn
                fab
                @click="
                  data.imagenesRedes.empleados = !data.imagenesRedes.empleados
                "
              >
                <v-icon
                  large
                  :color="
                    data.imagenesRedes.empleados == false
                      ? 'grey'
                      : 'green darken-3'
                  "
                  >check_circle</v-icon
                >
              </v-btn></v-col
            >
            <v-col cols="12" class="pt-0">
              <span class="font-weight-black title">Empleados</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="4" sm="6" v-if="data.imagenesRedes.activo">
          <v-row>
            <v-col cols="12">
              <v-btn
                fab
                @click="
                  data.imagenesRedes.clientes = !data.imagenesRedes.clientes
                "
              >
                <v-icon
                  large
                  :color="
                    data.imagenesRedes.clientes == false
                      ? 'grey'
                      : 'green darken-3'
                  "
                  >check_circle</v-icon
                >
              </v-btn></v-col
            >
            <v-col cols="12" class="pt-0">
              <span class="font-weight-black title">Clientes</span>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >¿Envía campañas de emails masivos?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-space-around">
          <v-btn fab @click="data.emails.activo = true">
            <v-icon
              large
              :color="data.emails.activo == 1 ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="data.emails.activo = false">
            <v-icon large :color="data.emails.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="data.emails.activo">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-text-field
              :error-messages="errors[0]"
              name="mailsistema"
              v-model="data.emails.sistema"
              label="¿Qué sistema utiliza?"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col col=12>
  <v-alert v-if="error" transition="scale-transition" type="error" outlined
        >Responde a todas las preguntas para continuar!</v-alert
      >
        </v-col>
      </v-row>
    
      <navButtons
        :page="page"
        :colores="colores"
        :ver="ver"
        v-on:stepper="$emit('stepper', $event)"
        v-on:GC="uploadForm('C')"
        v-on:GA="uploadForm('A')"
      ></navButtons>
      <!-- <template v-if="ver == false">
        <v-btn color="error" type="button" @click="$emit('stepper', '4')"
          >Atras</v-btn
        >
        <v-btn
          color="teal darken-1"
          dark
          type="button"
          @click="validateForm('form-5', 4)"
          >Guardar y Atras</v-btn
        >

        <v-btn :color="colores.primario" dark type="submit"
          >Guardar y continuar</v-btn
        >
      </template>
      <template v-else>
        <v-btn color="error" type="button" @click="$emit('stepper', '4')"
          >Atras</v-btn
        >

        <v-btn
          color="teal darken-1"
          dark
          type="button"
          :disabled="invalid"
          @click="$emit('stepper', '6')"
          >Siguiente</v-btn
        >
      </template> -->
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";

import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: `La casilla es requerida`,
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["token", "url", "paso5", "idFormulario", "guardar", "ver", "colores"],

  data: () => ({
    page: 5,
    data: {
      pcFijos: {
        activo: null,
        cantidad: 1,
      },
      pcPortatiles: {
        activo: null,
        cantidad: 1,
      },
      servLocales: {
        activo: null,
        donde: "",
      },
      copiasSeguridad: {
        activo: null,
        tiempo: "",
        seRealizan: "",
        seAlmacenan: "",
      },
      serviciosNube: {
        DropBox: false,
        Drive: false,
        OneDrive: false,
        Otros: "",
      },
      usoRedes: {
        activo: null,
        facebook: false,
        twitter: false,
        linkedin: false,
        whatsapp: false,
        instagram: false,
        telegram: false,
        pagWeb: {
          activo: false,
          campo: "",
        },
        otros: {
          activo: false,
          campo: "",
        },
      },
      imagenesRedes: {
        activo: null,
        socios: false,
        empleados: false,
        clientes: false,
      },
      emails: {
        activo: null,
        sistema: "",
      },
    },

    error: 0,
  }),
  methods: {
    async validateForm() {
      if (
        this.data.pcFijos.activo == null ||
        this.data.pcPortatiles.activo == null ||
        this.data.servLocales.activo == null ||
        this.data.copiasSeguridad.activo == null ||
        this.data.usoRedes.activo == null ||
        this.data.imagenesRedes.activo == null ||
        this.data.emails.activo == null
      ) {
        return false;
      } else {
        return await this.$refs.form5.validate();
        
      }
    },
    async uploadForm(type) {
      const isValid = await this.validateForm();
      if (!isValid) {
        this.error = true;
        return false;
      }
      try {
        this.axios.put(this.url + "paso5", {
          token: this.token,
          id: this.idFormulario,
          data: this.data,
        });
        if (type === "C") {
          this.$emit("stepper", this.page + 1);
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
        }
        return true;
      } catch (error) {
        console.log(error);
        this.error = true;
        return false;
      }
    },
  },
  watch: {
    paso5(datos) {
      this.data = datos;
    },
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    guardar(val) {
      if (val == true) {
        this.validateForm("form-5");
      }
    },
  },
};
</script>
