import Vue from 'vue'
import VueRouter from 'vue-router'
import formulario from '../components/App.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/ver/:id?/:token?',
    name: 'verFormulario',
    component: formulario
},
  {
    path: '/sector/:sector',
    name: 'formularioSectorial',
    component: formulario
},
{
    path: '/:id?/:token?',
    name: 'formulario',
    component: formulario
},
{
    path: '/:id?/:token?/sector/:sector',
    name: 'formularioIdTokenSector',
    component: formulario
},


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
