var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form7"},[_c('form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"headline"},[_vm._v("CONTRATOS CON TERCEROS")]),_c('h4',[_vm._v(" Indique si dispone de alguno de estos servicios ")])])],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"dark":"","color":_vm.colores.primario}},[_c('v-card-text',{staticClass:"white--text  px-2 font-weight-bold"},[_vm._v("Responde")])],1)],1)],1),_vm._l((_vm.preguntas.pregunta),function(pregunta,index){return _c('v-row',{key:index},[(_vm.preguntas.pregunta[index].id == 1)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.preguntas.pregunta[index].id == 1)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"justify-center",attrs:{"color":_vm.colores.secundario}},[_c('v-card-text',{staticClass:"d-flex  justify-center align-center px-2 font-weight-bold"},[_vm._v(_vm._s(pregunta.pregunta))])],1)],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[index].activo = 1}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[index].activo == 1
                          ? 'green darken-3'
                          : 'grey'}},[_vm._v("check_circle")])],1),_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[index].activo = 0}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[index].activo == 0 ? 'error' : 'grey'}},[_vm._v("cancel")])],1)],1)],1)],1),(false)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Razón Social *"},model:{value:(_vm.preguntas.datos[index].rSocial),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "rSocial", $$v)},expression:"preguntas.datos[index].rSocial"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|nif_cif_nie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Cif/Nif"},model:{value:(_vm.preguntas.datos[index].cif),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "cif", $$v)},expression:"preguntas.datos[index].cif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Email"},model:{value:(_vm.preguntas.datos[index].email),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "email", $$v)},expression:"preguntas.datos[index].email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Dirección"},model:{value:(_vm.preguntas.datos[index].dir),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "dir", $$v)},expression:"preguntas.datos[index].dir"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"min_value:01000|max_value:52999|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Código postal"},model:{value:(_vm.preguntas.datos[index].codPostal),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "codPostal", $$v)},expression:"preguntas.datos[index].codPostal"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"población"},model:{value:(_vm.preguntas.datos[index].pob),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "pob", $$v)},expression:"preguntas.datos[index].pob"}})]}}],null,true)})],1)],1)],1):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.preguntas.pregunta[index].id == 2)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"justify-center",attrs:{"color":_vm.colores.secundario}},[_c('v-card-text',{staticClass:"d-flex  justify-center align-center px-2 font-weight-bold"},[_vm._v(_vm._s(pregunta.pregunta))])],1)],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[index].activo = 1}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[index].activo == 1
                      ? 'green darken-3'
                      : 'grey'}},[_vm._v("check_circle")])],1),_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[index].activo = 0}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[index].activo == 0 ? 'error' : 'grey'}},[_vm._v("cancel")])],1)],1),(_vm.preguntas.datos[index].activo == 1)?_c('v-row',[(_vm.preguntas.datos[0].activo == 1)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"justify-center",attrs:{"color":_vm.colores.secundario}},[_c('v-card-text',{staticClass:"d-flex  justify-center align-center px-2 font-weight-bold"},[_vm._v("La asesoría laboral me la lleva mi asesor fiscal")])],1)],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[1].AsesoriasIguales = 1}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[1].AsesoriasIguales == 1
                          ? 'green darken-3'
                          : 'grey'}},[_vm._v("check_circle")])],1),_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[1].AsesoriasIguales = 0}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[1].AsesoriasIguales == 0
                          ? 'error'
                          : 'grey'}},[_vm._v("cancel")])],1)],1)]:_vm._e()],2):_vm._e(),(
                _vm.preguntas.datos[1].AsesoriasIguales == 0 ||
                  _vm.preguntas.datos[0].activo == 0
              )?[(false)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Razón Social *"},model:{value:(_vm.preguntas.datos[index].rSocial),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "rSocial", $$v)},expression:"preguntas.datos[index].rSocial"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|nif_cif_nie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Cif/Nif"},model:{value:(_vm.preguntas.datos[index].cif),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "cif", $$v)},expression:"preguntas.datos[index].cif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Email"},model:{value:(_vm.preguntas.datos[index].email),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "email", $$v)},expression:"preguntas.datos[index].email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Dirección"},model:{value:(_vm.preguntas.datos[index].dir),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "dir", $$v)},expression:"preguntas.datos[index].dir"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"min_value:01000|max_value:52999|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Código postal"},model:{value:(_vm.preguntas.datos[index].codPostal),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "codPostal", $$v)},expression:"preguntas.datos[index].codPostal"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"población"},model:{value:(_vm.preguntas.datos[index].pob),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "pob", $$v)},expression:"preguntas.datos[index].pob"}})]}}],null,true)})],1),_c('v-divider')],1):_vm._e()]:_vm._e()],2):_vm._e()],1),(pregunta.id != 1 && pregunta.id != 2)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"justify-center",attrs:{"color":_vm.colores.secundario}},[_c('v-card-text',{staticClass:"d-flex  justify-center align-center px-2 font-weight-bold"},[_vm._v(_vm._s(pregunta.pregunta))])],1)],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[index].activo = 1}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[index].activo == 1 ? 'green darken-3' : 'grey'}},[_vm._v("check_circle")])],1),_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.preguntas.datos[index].activo = 0}}},[_c('v-icon',{attrs:{"large":"","color":_vm.preguntas.datos[index].activo == 0 ? 'error' : 'grey'}},[_vm._v("cancel")])],1)],1),(false)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Razón Social *"},model:{value:(_vm.preguntas.datos[index].rSocial),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "rSocial", $$v)},expression:"preguntas.datos[index].rSocial"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|nif_cif_nie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Cif/Nif"},model:{value:(_vm.preguntas.datos[index].cif),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "cif", $$v)},expression:"preguntas.datos[index].cif"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Email"},model:{value:(_vm.preguntas.datos[index].email),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "email", $$v)},expression:"preguntas.datos[index].email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Dirección"},model:{value:(_vm.preguntas.datos[index].dir),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "dir", $$v)},expression:"preguntas.datos[index].dir"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"min_value:01000|max_value:52999|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Código postal"},model:{value:(_vm.preguntas.datos[index].codPostal),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "codPostal", $$v)},expression:"preguntas.datos[index].codPostal"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"población"},model:{value:(_vm.preguntas.datos[index].pob),callback:function ($$v) {_vm.$set(_vm.preguntas.datos[index], "pob", $$v)},expression:"preguntas.datos[index].pob"}})]}}],null,true)})],1),_c('v-divider')],1):_vm._e()],1):_vm._e()],1)}),_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"justify-center",attrs:{"color":_vm.colores.secundario}},[_c('v-card-text',{staticClass:"d-flex  justify-center align-center px-2 font-weight-bold"},[_vm._v("Otros")])],1)],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.otros.activo = 1}}},[_c('v-icon',{attrs:{"large":"","color":_vm.otros.activo == 1 ? 'green darken-3' : 'grey'}},[_vm._v("check_circle")])],1),_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.otros.activo = 0}}},[_c('v-icon',{attrs:{"large":"","color":_vm.otros.activo == 0 ? 'error' : 'grey'}},[_vm._v("cancel")])],1)],1),(_vm.otros.activo)?_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.otros.otrosDatos),function(otro,index){return _c('v-row',{key:index,attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors[0],"label":"Nombre servicio"},model:{value:(otro.servicio),callback:function ($$v) {_vm.$set(otro, "servicio", $$v)},expression:"otro.servicio"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-divider')],1)],1)}),1):_vm._e(),(_vm.otros.activo)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-center"},[(_vm.otros.otrosDatos.length > 1)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"fab":"","color":"amber darken-3","dark":""},on:{"click":function($event){_vm.añadirOtros()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("add")])],1)],1):_vm._e(),(_vm.otros.otrosDatos.length == 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":"","color":"amber darken-3","dark":""},on:{"click":function($event){_vm.añadirOtros()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("add")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[(_vm.otros.otrosDatos.length > 1)?_c('v-btn',{attrs:{"fab":"","color":"error","dark":""},on:{"click":function($event){return _vm.quitarOtros()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("remove")])],1):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-alert',{attrs:{"value":_vm.error,"transition":"scale-transition","type":"error","outlined":""}},[_vm._v("Responde a todas las preguntas para continuar!")]),_c('navButtons',{attrs:{"page":_vm.page,"colores":_vm.colores,"ver":_vm.ver},on:{"stepper":function($event){return _vm.$emit('stepper', $event)},"GC":function($event){return _vm.uploadForm('C')},"GA":function($event){return _vm.uploadForm('A')}}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }