<template>
  <form novalidate @submit.prevent="validateForm(7)">
    <v-card>
      <v-row row wrap class="d-none d-sm-flex">
        <v-col cols="12">
          <h3 class="headline">DOCUMENTACIÓN EN PAPEL</h3>
        </v-col>
        <v-col cols="6">
          <v-card dark :color="colores.primario" class="d-flex  justify-center align-center justify-center align-center" >
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Indique qué DOCUMENTACIÓN EN PAPEL guarda</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Respuesta</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Observaciones</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex d-sm-none">
        <v-col cols="12">
          <v-card dark :color="colores.primario">
            <v-card-text class="white--text  px-2 font-weight-bold text-uppercase"
              >Indique qué DOCUMENTACIÓN EN PAPEL guarda</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row
        align-center
        justify-center
        row
        v-for="(pregun, index) in preguntas.pregunta"
        :key="index"
      >
        <v-col cols="12" sm="6">
          <v-card :color="colores.secundario" class="justify-center bold">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >{{ pregun.pregunta }}</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" sm="3" class="d-flex justify-space-around">
          <v-btn fab @click="preguntas.datos[index].condicion = 1">
            <v-icon
              large
              :color="
                preguntas.datos[index].condicion == 1
                  ? 'green darken-3'
                  : 'grey'
              "
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="preguntas.datos[index].condicion = 0">
            <v-icon
              large
              :color="preguntas.datos[index].condicion == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex mt-5">
          <v-textarea
            height="40px"
            v-model="preguntas.datos[index].observaciones"
            label="Escribe aquí tus observaciones"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-alert
        :value="error"
        transition="scale-transition"
        type="error"
        outlined
        >Responde a todas las preguntas para continuar!</v-alert
      >

      <navButtons
        :page="page"
        :colores="colores"
        :ver="ver"
        v-on:stepper="$emit('stepper', $event)"
        v-on:GC="uploadForm('C')"
        v-on:GA="uploadForm('A')"
      ></navButtons>
    </v-card>
  </form>
</template>
<script>
export default {
  props: ["token", "url", "paso6", "idFormulario", "guardar", "ver", "colores"],
  data: () => ({
    page: 6,
    preguntas: {
      pregunta: [
        { id: 1, pregunta: "Hoja de encargo / ficha de alta o similar" },
        { id: 2, pregunta: "Ofertas / presupuestos a clientes" },
        { id: 3, pregunta: "Facturas a clientes" },
        { id: 4, pregunta: "Facturas de proveedores" },
        { id: 5, pregunta: "Curriculum Vitae" },
      ],
      datos: [
        {
          id: 1,
          condicion: null,
          observaciones: "",
        },
        {
          id: 2,
          condicion: null,
          observaciones: "",
        },
        {
          id: 3,
          condicion: null,
          observaciones: "",
        },
        {
          id: 4,
          condicion: null,
          observaciones: "",
        },
        {
          id: 5,
          condicion: null,
          observaciones: "",
        },
      ],
    },

    error: false,
  }),
  methods: {
    validateForm() {
      for (let pregunta of this.preguntas.datos) {
        if (pregunta.condicion == null) return false;

      }
      return true
      // let me = this;
      // let preguntaC = 0;
      // for (let index = 0; index < this.preguntas.pregunta.length; index++) {
      //   const pregunta = this.preguntas.datos[index];

      //   if (pregunta.condicion == null) {
      //     this.error = true;
      //   } else {
      //     preguntaC++;
      //   }
      //   if (preguntaC == 5) {
    },
    async uploadForm(type) {
      const isValid = await this.validateForm();
      if (!isValid) {
        this.error = true;
        return false;
      }
      try {
        this.axios.put(this.url + "paso6", {
          token: this.token,
          id: this.idFormulario,
          data: this.preguntas.datos,
        });
        if (type === "C") {
          this.$emit("stepper", this.page + 1);
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
        }
        return true;
      } catch (error) {
        console.log(error);
        this.error = true;
        return false;
      }
    },
  },
  watch: {
    paso6(datos) {
      this.preguntas.datos = datos;
    },
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    guardar(val) {
      if (val == true) {
        this.validateForm();
      }
    },
  },
};
</script>
