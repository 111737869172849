<template>
  <ValidationObserver ref="form3">
    <form>
      <v-row>
        <v-col cols="12">
          <h3 class="headline">RRHH</h3>
          <h4>
            Si tiene empleados indique sus datos y los departamentos a los que
            pertenecen (Administración, almacén…)
          </h4>
        </v-col>
      </v-row>
<v-row row wrap class="d-md-none d-flex">
  <v-col cols="12">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >EMPLEADOS</v-card-text
            >
          </v-card>
        </v-col>
</v-row>
      <v-row row wrap class="d-none d-md-flex">
        <v-col cols="0" md="6">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Nombre y Apellidos</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="0" md="2">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Dni</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="0" md="3">
          <v-card dark :color="colores.primario">
            <v-card-text
              class="white--text  px-2 font-weight-bold text-uppercase"
              >Departamento</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row row wrap v-for="(empleado, index) in empleados" :key="empleado.id">
        <v-col cols="12" md="6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="empleado.nombre"
              :error-messages="errors[0]"
              label="Nombre y Apellidos"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="2">
          <ValidationProvider rules="required|nif_nie" v-slot="{ errors }">
            <v-text-field
              v-model="empleado.dni"
              :counter="9"
              :error-messages="errors[0]"
              label="Dni"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="3">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <v-combobox
              v-model="empleado.departamento"
              :items="departamentos"
              v-on:click.native="addDep()"
              data-vv-as="Departamento"
              :error-messages="errors[0]"
              label="Departamento"
              required
            ></v-combobox>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="1" class="d-flex justify-center">
          <v-btn fab mdall color="error" dark @click="remCampEmpleados(index)">
            <v-icon large>remove</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row align-center justify-center row fill-height wrap>
        <v-col cols="12" offset-cols="11">
          <v-btn fab color="amber darken-3" dark @click="addCampEmpleados">
            <v-icon large>add</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card
            :color="colores.primario"
            class=" d-flex justify-center pa-2"
            height="100%"
          >
            <v-card-text class="white--text  px-2 font-weight-bold"
              >¿El listado anterior contiene todos los empleados?</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            :color="colores.secundario"
            class="d-flex justify-center align-center pa-2"
            height="100%"
          >
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >Sí. Son los que pone en la tabla.</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            :color="colores.secundario"
            class=" d-flex justify-center align-center pa-2"
            height="100%"
          >
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >No. Faltan datos de empleados, rellenaremos un excel a
              posteriori.</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-btn fab @click="EmpleadosCompletos = 'Si'">
            <v-icon
              large
              :color="EmpleadosCompletos == 'Si' ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn fab @click="EmpleadosCompletos = 'No'">
            <v-icon
              large
              :color="EmpleadosCompletos == 'No' ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-alert
            :value="error"
            transition="scale-transition"
            type="error"
            outlined
            >Responde a todas las preguntas para continuar!</v-alert
          >
        </v-col>
        <navButtons
          :page="page"
          :colores="colores"
          :ver="ver"
          v-on:stepper="$emit('stepper', $event)"
          v-on:GC="uploadForm('C')"
          v-on:GA="uploadForm('A')"
        ></navButtons>
        <!-- <v-col>
          <v-row>
            <v-col md="6" cols="12" class="order-md-2">
              <v-btn
                :disabled="invalid"
                :color="colores.primario"
                type="submit"
                class="white--text"
                >Guardar y continuar</v-btn
              >
            </v-col>
            <v-spacer> </v-spacer>
            <v-col md="6" cols="12" class="order-md-1">
              <v-btn
                color="teal darken-1"
                dark
                type="button"
                @click="validateForm('form-3', 2)"
                >Guardar y Atras</v-btn
              >
            </v-col>

            <v-col cols="12" class="order-md-3">
              <v-btn color="error" type="button" @click="$emit('stepper', '2')"
                >Atras</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="ver">
            <v-col cols="6">
              <v-btn color="error" type="button" @click="$emit('stepper', '2')"
                >Atras</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                color="teal darken-1"
                dark
                type="button"
                @click="$emit('stepper', '4')"
                >Siguiente</v-btn
              >
            </v-col>
          </v-row>
        </v-col> -->
      </v-row>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";

import { required } from "vee-validate/dist/rules";
import nif_nie from "../validators/nif_nie.js";

extend("nif_nie", {
  ...nif_nie,
  message: `La casilla debe ser un nif o nie`,
});

extend("required", {
  ...required,
  message: `La casilla es requerida`,
});
const Swal = require("sweetalert2");
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["token", "url", "paso3", "idFormulario", "guardar", "ver", "colores"],
  data: () => ({
    page: 3,
    empleados: [
      {
        id: 0,
        nombre: "",
        dni: "",
        departamento: "",
      },
    ],
    EmpleadosCompletos: null,
    departamentos: [],
    eId: 0,
    error: false,
  }),

  methods: {
    addDep() {
      this.empleados.forEach((empleado) => {
        if (
          !this.departamentos.includes(empleado.departamento) &&
          empleado.departamento != "" &&
          empleado.departamento != null
        ) {
          this.departamentos.push(empleado.departamento);
        }
      });
    },
    addCampEmpleados() {
      this.eId++;
      this.empleados.push({
        id: this.eId,
        nombre: "",
        dni: "",
        departamento: "",
      });
    },
    remCampEmpleados(id) {
      this.empleados.splice(id, 1);
    },
    async validateForm() {
      let result = await this.$refs.form3.validate();
      if (!result || this.EmpleadosCompletos == null) return false;
      return true;
    },
    async uploadForm(type) {
      let isValid = await this.validateForm();
      console.log(isValid);

      if (!isValid) {
        this.error = true;
        return false;
      }
      let data = {
        empleados: this.empleados,
        EmpleadosCompletos: this.EmpleadosCompletos,
      };
      if (this.empleados.length == 0) {
        let swal1 = await Swal.fire({
          title: "Esta seguro?",
          text: "Esta seguro de continuar sin trabajadores?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0097A7",
          cancelButtonText: "No, cancela!",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si!",
        });

        if (!swal1.value) {
          return false;
        }
      }
      try {
        await this.axios.put(this.url + "paso3", {
          token: this.token,
          id: this.idFormulario,
          data,
        });

        if (type === "C") {
          this.$emit("stepper", this.page + 1);
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
        }
      } catch (error) {
        this.error = true;
        console.log(error);
      }
    },
    /*
    volverYGuardar(scope) {
      let me = this;
      this.$validator.validateAll(scope).then(result => {

        if (result) {
          if (this.empleados.length == 0) {
            Swal.fire({
              title: "Esta seguro?",
              text: "Esta seguro de continuar sin trabajadores?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#0097A7",
              cancelButtonText: "No, cancela!",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si!"
            }).then(result => {
              if (result.value) {
                Vue.axios
                  .post(me.url + "paso3", {
                    token: me.token,
                    id: me.idFormulario,
                    empleados: me.empleados
                  })
                  .then(function(response) {
                    me.error = false;
                    me.$emit("stepper", 2);
                  })
                  .catch(function(error) {


                  });
              }
            });
          } else {
            Vue.axios
              .post(me.url + "paso3", {
                token: me.token,
                id: me.idFormulario,
                empleados: me.empleados
              })
              .then(function(response) {
                me.error = false;
                me.$emit("stepper", 2);
              })
              .catch(function(error) {
                  console.log(error)

              });
          }
        }
      });
    }*/
  },
  watch: {
    paso3(datos) {
      this.empleados = datos.empleados;
      this.EmpleadosCompletos = datos.EmpleadosCompletos;
    },
    guardar(val) {
      if (val == true) {
        this.validateForm("form-3");
      }
    },
  },
};
</script>
